var fbConfig = {
  apiKey: "AIzaSyCjAcJgorPogKZoHEagCQ4udDBWPpmzScE",
  authDomain: "nipponsake-cdd8f.firebaseapp.com",
  databaseURL: "https://nipponsake-cdd8f.firebaseio.com",
  projectId: "nipponsake-cdd8f",
  storageBucket: "nipponsake-cdd8f.appspot.com",
  messagingSenderId: "960406027841",
  appId: "1:960406027841:web:bf7be245b1b92f6f2e90c0",
  measurementId: "G-S9ELQSVQVE"
};

export default fbConfig;
